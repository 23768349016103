<template>
  <v-card rounded>
      <v-toolbar flat height="35">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <!--<v-btn icon v-show="minimize" @click.prevent="miniminzeMaximize()" >
          <v-icon>fas fa-expand-arrows-alt</v-icon>
        </v-btn>
        <v-btn icon v-show="!minimize"  @click.prevent="miniminzeMaximize()">
          <v-icon>fas fa-compress-arrows-alt</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>fas fa-sync</v-icon>
        </v-btn>-->
        <v-btn icon v-show="showhide" @click.prevent="showHide()"> 
          <v-icon>fas fa-chevron-up</v-icon>
        </v-btn>
        <v-btn icon v-show="!showhide" @click.prevent="showHide()">
          <v-icon>fas fa-chevron-down </v-icon>
        </v-btn>
        <!--<v-btn icon>
          <v-icon>fas fa-wrench</v-icon>
        </v-btn>-->
        <!--<v-btn icon>
          <v-icon>fas fa-times</v-icon>
        </v-btn>-->
      </v-toolbar>
      <v-card v-show="showhide" :class="heightSetter">
          <component :is="component" v-if="component" />
      </v-card>
  </v-card>
</template>


<script>

  export default {
    props:['type'],
    data() {
      return {
        component: null,
        title: '',
        minimize: true,
        showhide: true,
        heightSetter: '',
        sizeSetter: ''
      }
    },
    methods: {
      miniminzeMaximize: function () {
        this.minimize = !this.minimize
      },
      showHide: function () {
        this.showhide = !this.showhide
      },
      setHeight: function () {
        if (this.type == 'account') {
          this.heightSetter = 'aHeight'
        }
        else {
          this.heightSetter = 'height'
        }
      },
      syncData: function () {

      }
    },
    mounted() {
        if (this.type == 'budget') {
            this.component = () => import('./budgetWidget/budgetWidget.vue')
            this.title = "Budget"
        }
        else if (this.type == 'expense') {
            this.component = () => import('./expenseWidget/expenseWidget.vue')
            this.title = "Expense"
        }
        else if (this.type == 'income') {
            this.component = () => import('./incomeWidget/incomeWidget.vue')
            this.title = "Income"
        }
        //else {
        //    this.component = () => import('./noDataWidget.vue')
        //    this.title = "No Data"
        //}

      this.setHeight()
    },
  }

</script>

<style scoped>

  .v-icon {
    font-size: 15px
  }

  .height {
    min-height: 330px
  }

  .aHeight{
    max-height: 90px
  }

  .fullSize{
    width: 100vw !important;
    height: 100vh !important;
  }

</style>
